@import '../../theme/main.scss';

.guides-table {
    border-collapse: collapse;
    width: -webkit-fill-available;
    width: max-content;

    &-wrapper {
        border: 1px solid #d6dbe7;
        margin-top: 8px;
        padding: 16px;
        overflow: scroll;
        min-width: 50%;
        height: 100%;
    }

    &__full {
        width: 100%;
    }

    &__row {
        cursor: pointer;
    }

    &__th {
        font-weight: 500;
        font-size: 12px;
        color: #20222b;        
        text-align: left;
        padding: 0 8px;
        background-color: var(--color-grey-100);
        border: 0.5px solid var(--color-grey-200);        
        height: 32px;
    }

    &__td {
        font-weight: 400;
        font-size: 14px;
        color: #20222b;        
        text-align: left;
        padding: 0 8px;
        border: 0.5px solid var(--color-grey-200);        
        height: 30px;

        &_active{
            background-color: var(--color-grey-200);
        }

        &_checked {
            padding: 0 8px;
            border: 0.5px solid var(--color-grey-200);
            height: 30px;
        }

        &_marked{
            background-color: #ffe5ce;
        } 
    }
}

.statistic-table-wrapper {
    padding-left: 15px;
    width: 100%;
}

.buttons-wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
}