@import "../../../theme/main.scss";

.multi-language {

	&-form {
		padding: 0 16px 16px;
	}

	&-item {
		display: flex;
		align-items: center;
		gap: 16px;
	
		&__selector {
			flex: 0 0 125px;
		}
	
		&__button {
			flex: 0 0 auto;
		}
	}
}
