.waiter-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* align-self: center; */
}

.waiter_large {
  height: 100px;
  width: 100px;
  border: 10px solid #337eff;
  border-top: 10px solid transparent;
  border-radius: 70px;
}

.waiter_small {
  height: 20px;
  width: 20px;
  border: 4px solid #337eff;
  border-top: 5px solid transparent;
  border-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .waiter {
    animation: waiter-spin infinite 1s linear;
  }
}

@keyframes waiter-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
