

@mixin static_title_h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-400);
}

@mixin input_label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-grey-400);
}

@mixin body-text-14 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-grey-400);
}