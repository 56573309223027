.user-agreement__contant{
    width: 1247px;
    display: flex;
    margin: auto;
    flex-direction: column;
    height: 1020px;
}

.user-agreement__header{
    margin-top: 60px;
    margin-bottom: 50px;
    font-size: 34px;
    font-weight: 700;
}

.user-agreement__text-block{
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #606580;
}

.user-agreement__subheader{
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    color: #272a38;
    margin-bottom: 25px;
    margin-top: 50px;
}

.user-agreement__button-wrapper{
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.user-agreement__agree-button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 107px;
    height: 40px;
    margin-top: 102px;
    background-color: #337eff;
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-decoration: none;
}