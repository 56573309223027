.checkbox {
  flex: 0 0 auto;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  & input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-grey-300);
    background-color: var(--color-grey-100);
    border-radius: 2px;
  }

  & input:checked {
    border: none;
    background-image: url("../../theme/svg/checkbox-checked.svg");
  }

  & .hidden {
    color: var(--color-text-300);
  }
}
