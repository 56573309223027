@import "../../theme/main.scss";

.chips-field-block {
  flex: 1;
}

.chips-field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    @include input_label;
    padding-left: 16px;
  }

  &__block {
    @include basic-input;
    width: 100%;
    min-height: 40px;
    height: fit-content;
    display: flex;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--color-primary);
    }

    &:focus {
      border-color: var(--color-primary);
    }

    &:focus-visible {
      outline: 1px solid hsl(218, 100%, 70%);
    }

    &--error {
      border-color: var(--color-danger);
    }
  }

  &__list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__error {
    height: 16px;
    padding-left: 16px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--color-danger);
  }
}
