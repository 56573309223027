

.guides-page-subcontrols{
	min-height: 40px;
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: auto;
}


.table-limit {
	&-control{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 16px;
		gap: 12px;

		&__name{
			font-weight: 600;
			font-size: 12px;
			color: #777b92;
		}

		&__selector{
			width: 100px;
			height: 24px;
			background-color: #f4f6fb;
			border: 1px solid #337eff;
			border-radius: 2px;
			margin-left: 12px;

			&:focus-visible{
				outline: 2px solid #d6e5ff;
			}
		}

		.dropdown-block {

			&__field {
				padding: 0 16px;
				height: 30px;
				gap: 8px;

				&:disabled {
					background: var(--color-grey-100);
					color: var(--color-grey-400);
					cursor: not-allowed;
				}
			}

			&__options {
				min-width: 80px;
			}
		}
	}

}


.paginator{
	display: flex;
	flex-direction: row;
	align-items: center;

	&_hide{
		display: none;
	}

	&__info{
		display: flex;
		font-weight: 600;
		font-size: 12px;
		color: #bfc6d7;
		margin-right: 16px;
	}

	&__button{
		height: 24px;
		width: 77px;
		margin-left: 16px;
		margin-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: 600;
		color: #bfc6d7;

		&_active{
			cursor: pointer;
			color: #777b92;
		}
	}

	&__pages{
		display: flex;
		flex-direction: row;
	}

	&__page {
		&-button{
			height: 24px;
			width: 40px;
			margin-left: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			font-weight: 600;
			color: #777b92;
			cursor: pointer;

			&_active{
				color: #337eff;
				background-color: #d6e5ff;
			}
		}

		&-shim{
			height: 24px;
			width: 40px;
			margin-left: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			font-weight: 600;
			color: #777b92;
			cursor:default;
		}
	}
}
