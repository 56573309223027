.double-description {
  height: 100%;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.double-description_outer {
}

.double-description_inner {
}

.double-description__preview {
  font-size: 14px;
  font-weight: 400;
  color: #50546b;
}

.double-description-modal__wrapper {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.double-description-modal__wrapper_visable {
  display: flex;
}

.double-description-modal {
  width: 788px;
  height: 680px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  flex-direction: column;
}

.double-description-modal__header {
  height: 50px;
  border-bottom: 1px solid #d6dbe7;
  padding: 0 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.double-description-modal__header-caption {
  font-size: 14px;
  font-weight: 600;
}

.double-description-modal__header-button {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.double-description-modal__header-button-img {
  height: 16px;
  width: 16px;
  background-image: url('../../../public/accets/img/description/cross.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.double-description-modal__content {
  flex-grow: 1;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.double-description-modal__content-header {
  height: 16px;
  display: flex;
  width: 100%;
  color: #777b92;
  margin-top: 11px;
  margin-bottom: 3px;
}

.double-description-modal__text-content {
  height: 200px;
  width: 675px;
  border: 1px solid #d6dbe7;
  border-radius: 2px;
  padding: 11px 65px 11px 14px;
  overflow: scroll;
  cursor: text;
  font-size: 14px;
  font-weight: 400;
  color: #50546b;
}

.double-description-modal__content-button {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.double-description-modal__content-button-img {
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../public/accets/img/page_3/doubleArrowUp.svg');
}

.double-description-modal__border {
  border: 0.5px solid #d6dbe7;
  width: 752px;
  height: 0;
  display: flex;
  align-self: center;
}

.double-description-modal__footer {
  height: 72px;
  border-top: 1px solid #d6dbe7;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0 16px;
}

.double-description-modal__footer-button {
  height: 40px;
  width: 93px;
  background-color: #337eff;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.double-description-modal__span_selected {
  background-color: #d6dbe7;
}
