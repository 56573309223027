:root {
    --color-primary: #337EFF;
    --color-primary-200: #0F62FE; 
    --color-primary-300: #003ED6;
    --color-primary-400: #295095; 

    --color-secondary: #8D91A4;
    --color-success: #49AB4D;
    --color-warning: #FF7C0A;
    --color-danger: #F14141;
    --color-disable: #d6dbe7;
    --color-white: #fff;
    --color-tooltip: #303340;
    --color-hidden: #E8EBF0;

    --color-grey-100: #F4F6FB;
    --color-grey-200: #D6DBE7;
    --color-grey-300: #BFC6D7;
    --color-grey-400: #777B92;
		
    
    --color-light-blue-30: #D6E5FF;
    
		--color-text-200: #606580;
    --color-text-300: #50546B;
    --color-text-400: #20222B;
}