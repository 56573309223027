.year-calendar {
  width: 200px;

  .react-calendar {
    &__navigation {
      & button {
        min-width: 20px;
      }

      & button:disabled {
        background: unset;
        color: var(--color-grey-400);
      }
    }
  }
}