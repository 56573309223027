/* .guides-page__controls {
  
  width: 100%;
  border: 1px solid #d6dbe7;
  display: flex;
  flex-direction: row;
  align-items: center;
} */

/* .guides-page__control {
  height: 24px;
  padding: 0 16px;
  border-right: 1px solid #bfc6d7;
  display: flex;
  align-items: center;
} */

/* .guides-page__control_elastic {
  flex-grow: 1;
} */

/* .guides-page__control-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  min-width: 100px;
} */

.control__img {
  width: 13px;
  height: 13px;
  align-self: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* cursor: pointer; */
}

.control__img_pen {
  background-image: url('../../../public/accets/img/page_4/pen.svg');
}

.control__img_pen-active {
  background-image: url('../../../public/accets/img/page_4/pen_active.svg');
  cursor: pointer;
}

.control__img_history {
  width: 14px;
  height: 14px;
  background-image: url('../../../public/accets/img/page_4/history.svg');
}

.control__img_history-active {
  cursor: pointer;
  background-image: url('../../../public/accets/img/page_4/history-active.svg');
}

.control__img_eye {
  width: 16px;
  height: 14px;
  background-image: url('../../../public/accets/img/page_4/eye.svg');
}

.control__img_eye-active {
  background-image: url('../../../public/accets/img/page_4/eye_active.svg');
  cursor: pointer;
}

.control__img_eye-crossed-active {
  width: 16px;
  height: 14px;
  background-image: url('../../../public/accets/img/page_4/eye_crossed.svg');
  cursor: pointer;
}

.control__img_trash {
  width: 15px;
  height: 15px;
  background-image: url('../../../public/accets/img/page_4/trash.svg');
}

.control__img_trash-active {
  cursor: pointer;
  background-image: url('../../../public/accets/img/page_4/trash-active.svg');
}

.guides-page__control-patch {
  flex-grow: 1;
}

/* .guides-page__control-header {
  font-weight: 600;
  font-size: 12px;
  color: #777b92;
  margin-right: 3px;
} */

/* .guides-page__select {
  height: 32px;
  width: 260px;
  margin-left: 6px;
} */

/* .guides-page__form{
  position: relative;
}

.guides-page__input {
  margin-left: 8px;
  height: 26px;
  width: 260px;
  padding-right: 34px;
} */

/* .guides-page__input-img{
  position: absolute;
  z-index: 1;
  height: 16px;
  width: 16px;
  top: 8px;
  right: 16px;
  background-image: url('../../../public/accets/img/page_4/loupe.svg');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: auto;
} */
/* 
.guides-page__input_disabled {
  background-color: #d6dbe7;
} */

/* .guides-page__button {
  height: 32px;
  width: 152px;
  background-color: #337eff;
  margin-left: 8px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.guides-page__button_add {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.guides-page__button_copy {
  height: 32px;
  width: 48px;
} */

.control__img_copy {
  height: 16px;
  width: 16px;
  background-image: url('../../theme/svg/editor_copy.svg');
}

/* .guides-page__menu-button {
  height: 32px;
  width: 32px;
  margin: 0 16px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
} */

/* .guides-page__menu-button_active {
  cursor: pointer;
  background-color: #d6e5ff;
} */

.button__ghost_active {
  background-color: #d6e5ff;
}

.guides-page__menu-img {
  height: 12px;
  width: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-img__point {
  height: 2px;
  width: 2px;
  border-radius: 1px;
  background-color: #777b92;
}

.menu-img__point_active {
  background-color: #337eff;
}

.guides-page__main-menu-wrapper{
    
}

.guides-page__main-menu-wrapper_active{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: 1;
}

.guides-page__main-menu {
  height: fit-content;
  width: 260px;
  position: absolute;
  right: 25px;
  top: 120px;
  border: 1px solid #d6dbe7;
  background-color: #ffffff;
  flex-direction: column;
  display: none;
}

.guides-page__main-menu_active {
  display: flex;
}

.guides-page__main-menu-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
}

.guides-page__main-menu-section_small {
  border-bottom: 1px solid #d6dbe7;
}

.guides-page__main-menu-button {
  height: 40px;
  color: #20222b;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  cursor: default;
}

.guides-page__main-menu-button-active {
  height: 40px;
  color: #20222b;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
}

.guides-page__main-menu-button-active:hover {
  background-color: #d6e5ff;
}

.main-menu-button__img {
  height: 16px;
  width: 16px;
  margin-right: 4px;
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
}

.main-menu-button__img_eye {
  height: 15px;
  width: 21px;
  background-image: url('../../../public/accets/img/page_4/menuEye.svg');
}

.main-menu-button__img_eye-crossed {
  height: 15px;
  width: 21px;
  background-image: url('../../../public/accets/img/page_4/menuEyeCrossed.svg');
}

.main-menu-button__img_download {
  background-image: url('../../../public/accets/img/page_4/menuDounload.svg');
}

.main-menu-button__img_download-active {
  background-image: url('../../../public/accets/img/page_4/menuDounloadActive.svg');
}

.main-menu-button__img_upload {
  background-image: url('../../../public/accets/img/page_4/menuUpload.svg');
}

.main-menu-button__img_upload-active {
  background-image: url('../../../public/accets/img/page_4/menuUploadActive.svg');
}
