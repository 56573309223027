@import '../../theme/main.scss';

.guides-page {
    &__controls {
        padding: 8px 16px;
        border: 1px solid var(--color-grey-200);

        &__standart {
            @include flex-all-sb;
            gap: 16px;
        }

        &__secondary {
            @include flex-all-sb;
            // display: none;
            align-items: flex-end;
            gap: 16px;
            padding-top: 16px;
            margin: 8px 0;
            position: relative;

            &::before {
                content: '';
                height: 1px;
                background-color: var(--color-grey-200);
                position: absolute;
                top: 0;
                left: -16px;
                right: -16px;
                display: block;
            }

            &__active{
                display: flex;
								align-items: flex-start;
            }
        }

				&__filtres {
					max-width: 60%;
					display: flex;
					flex-wrap: wrap;
					gap: 16px;
				}

				&__search {
					display: flex;
					align-items: flex-end;
					gap: 16px;
					width: 740px;
				}
    }

    &__control {
        @include flex-v-center;
        height: 100%;
        padding-right: 16px;
        border-right: 1px solid var(--color-grey-300);

        &_elastic {
            flex-grow: 1;
            gap: 8px;
        }

        &-item {
            @include flex-v-center;
            gap: 4px;
        }

        &-column {
            @include flex-h-column;
            gap: 4px;

            & div:first-child {
                padding-left: 8px;
            }
        }

        &-name {
            @include static_title_h4;
        }

        &-header, &-title {
            @include input_label;
            margin-right: 3px;

            &__required {
                &::after {
                    content: '*';
                    font-size: 12px;
                    color: var(--color-danger);
                    margin-left: 4px;
                }
            }
        }

        &_last{
            border-right: 0;
        }
    }

    &__form {
        position: relative;
        height: 100%;
        flex-grow: 1;

        &__small {
            flex-grow: 0;
        }
    }

		&__opacity {
			opacity: 0.2;
			cursor: not-allowed;
			pointer-events: all;
		}

    &__input {
        @include basic-input;
        padding-right: 40px;
        width: 100%;

				&-img{
					position: absolute;
					height: 16px;
					width: 16px;
					top: 50%;
					transform: translateY(-50%);
					right: 16px;
					background-image: url('../../../public/accets/img/page_4/loupe.svg');
					background-repeat: no-repeat;
					background-size: contain;
					cursor: pointer;
				}
    }

    &__select {
        @include basic-select;
        max-width: 125px;
        height: 34px;

        &__big {
            max-width: 260px
        }
    }

    &__date {
        @include basic-input-date;
        max-width: 120px;
        margin-right: 30px;
    }
}