@import "../../theme/main.scss";

.condition-table-block{
	display: flex;
	flex-direction: column;
}

.condition {
	&-section{
		min-height: 75px;
		height: auto;
		border: 1px solid var(--color-grey-200);
		border-top: 0;
		display: flex;

		&__attributes{
			width: 350px;
			min-height: 75px;
			height: auto;
			@include flex-h-column;
			justify-content: center;
			padding: 10px 0;
			// border-bottom: 1px solid var(--color-grey-200);
		}

		&__row{
			display: flex;
			flex-direction: row;
		}

		&__parameter{
			width: 167px;
			padding-left: 16px;
			color: var(--color-text-300);
			font-size: 14px;
			font-weight: 600;
		}

		&__value{
			width: 151px;
			padding-left: 16px;
			color: var(--color-text-300);
			font-size: 14px;
			font-weight: 400;
		}

		&__description{
			flex-grow: 1;
			/* background-color: aquamarine; */
			padding: 0 32px 0 16px;
		}
	}
}

.report-section{
	padding: 16px;
	border: 1px solid var(--color-grey-200);
	border-top: 0;
}

.hidden { 
	color: var(--color-grey-300);

	&-bg {
		background-color: var(--color-hidden);
	}
}