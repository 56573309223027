@import "../../theme/main.scss";

.condition-intervals {
  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    @include flex-v-center;
    flex: 0 0 48px;
    margin-bottom: 8px;
    border: 1px solid var(--color-grey-200);
  }

  &__footer {
    @include flex-v-center;
    justify-content: flex-end;
    padding: 16px;
    border: 1px solid var(--color-grey-200);
    border-top: unset;
  }
}
