.product-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 5px 7px 15px;
  border: 0.5px solid #d6dbe7;
}

.product-list__item.hidden {
    background-color: var(--color-hidden);
}

.product-list__item.hidden .pl-item__caption {
    color: var(--color-text-300);
}

.pl-item__img {
  width: 16px;
  height: 16px;
  background-image: url('../../../public/accets/img/page_3/copy.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 4px;
	flex-shrink: 0;
}
