.product-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding-left: 8px; */
    padding-top: 8px;
    padding-bottom: 8px;
}

.product-list__header{
    display: flex;
    /* width: 100%; */
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    gap:4px;
}
.button{
}

.button_active{
    cursor: pointer;
}

.button_24x24{
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    /* padding: 0; */
}

.image__copy{
    width: 16px;
    height: 16px;
    background-image: url('../../../public/accets/img/page_3/copy.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.image__arrow-down{
    height: 4px;
    width: 6px;
    background-image: url('../../../public/accets/img/page_3/arrowDown.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.rotatedArrow{
 transform: rotate(180deg);
}

.pl-header__caption{
    display: flex;
    gap: 8px;
    flex-grow: 1;
    align-items: center;
}   


.pl-header__caption__moreItemsCounter {
    font-size:12px;
    color:var(--color-secondary);
}

.pl-header__caption__moreItemsCounter:hover {
    cursor: pointer;
}

.product-list__body{
    display: none;
}

.product-list__body_active{
    flex-direction: column;
    align-items: flex-start;
}