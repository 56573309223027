.header {
  /* height: calc(100vw/32); */
  height: 60px;
  background-color: #20222b;
  /* padding-top: calc(100vw/128); */
  /* padding-bottom: calc(100vw/128); */
  /* padding-left: calc(100vw/96); */
  padding-left: 20px;
  /* padding-right: calc(100vw/96); */
  padding-right: 20px;
  /* line-height: calc(100vw/64); */
  line-height: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: #a1a5b7;

  font-size: 14px;
  min-width: 1230px;
}

.header__group {
  display: flex;
  flex-direction: row;
}

.header__group_right {
  flex-grow: 1;
  justify-content: end;
}

.header__link-wrapper {
  cursor: pointer;
  display: flex;
  align-content: center;
  border-bottom: 2px solid #404355;
}

.header__link {
  /* line-height: calc(100vw/32); */
  font-family: 'Inter';
  font-weight: 600;
  /* font-size: calc(100vw/137.14); */
  font-size: 14px;
  color: #a1a5b7;
}

.header__link::first-letter {
  text-transform: capitalize;
}

.header__link-wrapper_active {
  border-bottom-color: #337eff;
}

.header__link-wrapper_active .header__link {
  /* color: white; */
  color: #337eff;
}

.header__a{
  text-decoration: none;
  color: inherit;  
  line-height: 60px;
  padding: 0 20px;
  display: block;
}

.header__a::first-letter {
  text-transform: uppercase;
}

.header__user-section {
  display: flex;
  flex-direction: row;
  /* width: calc(100vw/12.47); */
  flex-grow: 1;
  /* height: calc(100vw/48); */
  height: 40px;
  justify-content: flex-end;
  align-self: center;
}

.header__user-wrapper {
  display: flex;
  cursor: pointer;
}

.header__user-info {
  /* width: calc(100vw/19.59); */
  /* height: calc(100vw/48); */
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header__user-name {
  font-family: 'Inter';
  /* font-size: calc(100vw/137.14); */
  font-size: 14px;
  /* line-height: calc(100vw/96); */
  line-height: 20px;
  /* height: calc(100vw/96); */
  height: 20px;
  font-weight: 800;
  text-align: right;
  display: flex;
  text-transform: capitalize;
}

.header__user-position {
  /* height: calc(100vw/96); */
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.position-name {
  /* height: calc(100vw/160); */
  height: 12px;
  /* line-height: calc(100vw/160); */
  line-height: 12px;
  font-family: 'Inter';
  /* font-size: calc(100vw/192); */
  font-size: 10px;
  font-weight: 500;
  color: #a1a5b7;
  /* align-self: center; */
}

.position-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* width: calc(100vw/960); */
  /* height: calc(100vw/160); */
  height: 12px;
  padding: 1px;
  /* margin-left: calc(100vw/240); */
  margin-left: 8px;
}

.position-button__point {
  height: 2px;
  width: 2px;
  border-radius: 1px;
  background-color: #49ab4d;
}

.header__user-logo {
  background-repeat: no-repeat;
  background-size: contain;
  /* height: calc(100vw/54.86); */
  height: 35px;
  /* width: calc(100vw/54.86); */
  width: 35px;
  display: flex;
  align-self: center;
  /* margin-left: calc(100vw/96); */
  margin-left: 20px;
}

.header__user-logo_logaut {
  background-image: url('../../../public/accets/img/user_logaut.svg');
}

.header__user-logo_login {
  background-image: url('../../../public/accets/img/user_login.svg');
}

.header__select {
  /* width: calc(100vw/37); */
  background-color: inherit;
  border: 0;
  color: inherit;
  text-transform: uppercase;
  /* font-size: calc(100vw/120); */
  font-size: 16px;
  /* margin-left: calc(100vw/48); */
  margin-left: 40px;
  cursor: pointer;
}

.header__select:focus-visible {
  border: 0;
  outline-width: 0;
}

.header__options {
  padding: 0;
}

.header__user-menu {
  height: 214px;
  width: 263px;
  position: absolute;
  top: 67px;
  right: 100px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.header__user-menu_unvisable {
  display: none;
}

.user-menu__info {
  min-height: 36px;
  height: auto;
  background-color: #20222b;
  display: flex;
  flex-direction: column;
  padding: 14px 15px;
}

.user-menu__user-name {
  line-height: 20px;
  font-size: 14px;
  font-weight: 800;
  color: white;
  text-transform: capitalize;
}

.user-menu__user-position {
  line-height: 16px;
  font-weight: 700;
  font-size: 12px;
}

.user-menu__submenu {
  height: 140px;
  border-radius: 2px;
  background-color: #20222b;
  border: 1px solid #303340;
  display: flex;
  flex-direction: column;
}

.user-menu__contacts {
  height: 66px;
  border-bottom: 1px solid #303340;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.user-menu__logaut {
  height: 74px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-menu__logaut-img {
  width: 16px;
  height: 16px;
  background-image: url('../../../public/accets/img/logout.svg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 8px;
}

.user-menu__logaut-caption {
  line-height: 16px;
  font-size: 14px;
  color: #f4f6fb;
  font-weight: 400;
}

.header__cover{
  display: none;
}

.header__cover_active{
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
}