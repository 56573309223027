
.selection-page__main-button{
    background-color: blue;
    width: 200px;
    margin: auto;
    margin-top: 20px;
    padding: 15px;
    color: whitesmoke;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
}

.selection-page__clean-filters{
    color: blue;
    margin: auto;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    width: 200px;
}

.selection-page{
    background-color: #ffffff;
    min-height: 100vh;
}
