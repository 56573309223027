.calendar {
  width: 314px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-family: "Inter" !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  border: 1px solid var(--color-grey-200) !important;
  border-radius: 2px;
  background-color: var(--color-white) !important;

  .react-calendar {
    &__navigation {
      margin: 0;
      & button:enabled:hover,
      button:enabled:focus {
        background: unset;
        color: var(--color-primary);
      }

      & button:disabled {
        background: unset;
        color: var(--color-grey-300);
      }
    }

    &__tile {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-grey-400);
    }

    &__tile:disabled {
      background: unset;
      color: var(--color-grey-300)
    }

    &__tile:enabled {
      &:hover,
      &:focus {
        background: var(--color-primary);
        color: var(--color-white);
      }
    }

    &__tile {
      &--active {
        background: var(--color-primary);
        color: var(--color-white);
      }

      &--now {
        background: var(--color-grey-200);
      }

      &--hover {
        background-color: var(--color-light-blue-30) !important;
        color: var(--color-primary);
      }

      &--range {
        background: var(--color-light-blue-30);
        color: var(--color-primary);
      }

      &--rangeStart,
      &--rangeEnd {
        background: var(--color-primary);
        color: var(--color-white);
      }

      &--hoverStart,
      &--hoverEnd {
        background: var(--color-primary) !important;
        color: var(--color-white);
      }
    }

    &__month-view__weekdays {
      height: 40px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-grey-400);

      & abbr {
        text-decoration: none;
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--color-grey-300) !important;
  }
}
