@import "../../theme/main.scss";

.dropdown-multiselect {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    @include input_label;
    padding-left: 16px;

    &--error {
      color: var(--color-danger);
    }

    & .required {
      color: var(--color-danger);
    }
  }

  &__container {
    position: relative;
  }

  &__field {
    @include basic-input;
    height: unset;
    display: flex;
    width: 100%;
    min-height: 40px;
    padding: 0 8px;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--color-primary);
    }

    &:focus {
      border-color: var(--color-primary);
      border: 1px solid var(--color-primary);
      outline: 1px solid hsl(218, 100%, 70%);
    }

    &--error {
      border-color: var(--color-danger);
    }

    &[data-show-tooltip="true"]::after {
      content: attr(data-tooltip);
      position: absolute;
      z-index: 11;
      margin-top: 4px;
      top: 100%;
      left: 10%;
      right: 10%;
      padding: 8px;
      background-color: var(--color-tooltip);
      color: #fff;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }

    &:hover::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &__list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    gap: 8px;
  }

  &__selected-value {
    flex: 1 1 auto;
  }

  &__icons {
    display: flex;
    gap: 16px;
    height: 38px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;

    &:hover {
      & .dropdown-multiselect__chevron-down {
        border-color: var(--color-primary) transparent transparent transparent;
      }

      & svg {
        fill: var(--color-primary);
      }
    }
  }

  &__chevron-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: var(--color-grey-400) transparent transparent transparent;
    transition: border-color 0.2s ease;
  }

  &__options {
    position: absolute;
    z-index: 10;
    margin-top: 4px;
    width: 100%;
    max-height: 438px;
    padding: 16px;
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    background-color: var(--color-white);
    overflow-y: auto;

    & .multiselect-block__options {
      overflow: unset;
    }
  }

  .top {
    bottom: 40px;
    margin-bottom: 4px;
  }

  .bottom {
    margin-top: 4px;
  }

  &__option {
    width: 100%;
    padding: 11px 24px 11px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--color-grey-100);
    }

    &.hidden {
      color: var(--color-text-300);
    }
  }

  &__no-items {
    width: 100%;
    padding: 11px 24px 11px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }

  &__search {
    padding: 0 16px 16px;
  }

  &__waiter {
    padding-top: 16px;
  }

  &__error {
    display: flex;
    align-items: center;
    height: 16px;
    padding-left: 16px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--color-danger);
  }
}
