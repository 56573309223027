.notification {
    display: none;
    position: fixed;
    z-index: 100;
    top: 100px;
    right: 20px;
    height: auto;
    min-height: 90px;
    width: 520px;

    &__active {
        display: block;
    }

    &__block {
        padding: 16px;
        border-style: solid;
        border-width: 1px;
        position: relative;
        border-radius: 2px;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-300);

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
        }

        &__info {
            background-color: #D6E5FF;
            border-color: var(--color-primary);

            &::before {
                background-color: var(--color-primary);
            }
        }

        &__warning {
            background-color: #FFE5CE;
            border-color: var(--color-warning);

            &::before {
                background-color: var(--color-warning);
            }
        }

        &__success {
            background-color: #DBEEDB;
            border-color: var(--color-success);

            &::before {
                background-color: var(--color-success);
            }
        }

        &__danger {
            background-color: #FCD9D9;
            border-color: var(--color-danger);

            &::before {
                background-color:  var(--color-danger);
            }
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__icon {
        margin-right: 16px;
				width: 24px;
				height: 24px;
				display: flex;
				flex: 0 0 auto;
    }

    &__header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    
    &__header-caption {
        font-weight: 800;
    }
    
    &__header-link {
        font-weight: 400;
        text-decoration: underline;
        color: var(--color-primary);
        cursor: pointer;
    }

    &__message {
        white-space: pre-line;
    }

    &__close {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 16px;
        flex: 0 0 auto;
    }
}