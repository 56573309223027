.dialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  backdrop-filter: blur(2px);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 999;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &__active {
    opacity: 1;
    pointer-events: all;
    background-color: rgba(119, 123, 146, 0.5);
    display: flex;
  }

  &__modal {
    position: absolute;
    top: 60px; // header height
    right: 0;
    background-color: var(--color-white);
    max-width: 700px;
    min-height: 300px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    &__content {
      padding: 30px;
    }

    &__bottom {
      margin-top: auto;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      padding: 16px;
      border-top: 1px solid var(--color-grey-200);
    }
  }
}
