.condition-table{
    display: flex;
    flex-direction: column;
}

.condition-table-header{
    display: flex;
    flex-direction: row;
    height: 65px;
    border-left: 1px solid #d6dbe7;
    border-right: 1px solid #d6dbe7;
    border-bottom: 1px solid #d6dbe7;
    padding: 16px;
}

.condition-table-header__item{
    width: 295px;
    height: 32px;
    border: 0.5px solid #d6dbe7;
    border-left: 0;
    display: flex;
    padding-left: 8px;
    align-items: center;
    background-color: #0089ff;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
}

.condition-table-header__item_left{
    border: 0.5px solid #d6dbe7;
}

.condition-table-header__item_right{
    width: 885px;
}