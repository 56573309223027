
.search-icon {
  width: 16px;
  height: 16px;
  background-image: url('../../public/accets/img/page_4/loupe.svg');
  background-repeat: no-repeat;
}

.clear-field-icon {
  width: 16px;
  height: 16px;
  background-image: url('../../public/accets/img/page2/clear_field.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.alert-icon {
  width: 16px;
  height: 16px;
  background-image: url('../../public/accets/img/alert.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.copy-icon {
  width: 16px;
  height: 16px;
  background-image: url('../../public/accets/img/copy_white.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.add-to-icon {
  width: 16px;
  height: 16px;
  background-image: url('../../public/accets/img/add-to-list.svg');
  background-repeat: no-repeat;
  background-position: center;
}

