@import "../../theme/main.scss";

.modal-backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 999;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &.active {
    opacity: 1;
    pointer-events: all;
    background-color: rgba(119, 123, 146, 0.5);
  }

  & .modal-container {
    width: 432px;
    background: var(--color-white);
    border: 1px solid var(--color-disable);
    box-shadow: 0px 8px 32px hsla(222, 23%, 80%, 0.5);
    border-radius: 2px;
    text-align: center;
  }
}

.modal {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 32px 16px;

    & h4 {
      @include static_title_h4;
      font-weight: 800;
    }

    & div {
      @include body-text-14;
    }
  }

  &__buttons {
    padding: 16px;
  }
}

.modal-button {
  width: 100%;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
    margin-top: 16px;
  }

  .modal-button-content {
    display: flex;
    align-items: center;

    * {
        margin: 0 3px;
    }
  }
}
