@import '../../theme/main.scss';

.table {
    border-collapse: collapse;
    width: -webkit-fill-available;
    width: max-content;

    &-wrapper {
        border: 1px solid #d6dbe7;
        margin-top: 8px;
        padding: 16px;
        overflow: auto;
        height: 100%;
        min-width: 50%;

        table {
            border-spacing: 0;
        }
    }

    &__full {
        width: 100%;
    }

    &__row {
        cursor: pointer;
    }

    &__th {
        font-weight: 500;
        font-size: 12px;
        color: #20222b;        
        text-align: left;
        padding: 0 8px;
        background-color: var(--color-grey-100);
        border: 0.5px solid var(--color-grey-200);        
        height: 32px;
    }

    &__td {
        font-weight: 400;
        font-size: 14px;
        color: #20222b;        
        text-align: left;
        padding: 0 8px;
        border: 0.5px solid var(--color-grey-200);        
        height: 30px;

        &_active{
            background-color: var(--color-light-blue-30);
        }

        &_checked {
            padding: 0 8px;
            border: 0.5px solid var(--color-grey-200);
            height: 30px;
        }

        &_marked{
            background-color: #ffe5ce;
        } 

        &_dark{
            background-color: #d6dbe7;
        }

        &_hidden{
            color: var(--color-secondary);
        }
    }
}