.switch {
    &__container {
        height: 40px;
    }

    &-block {
        $this: &;
        position: relative;
        display: flex;
        gap: 8px;
        height: auto;
    
        &__input {
            opacity: 0;
            width: 0;
            height: 0;
            display: none;
    
            &:checked + #{$this}__slider {
                background-color: #2196F3;
    
                &:before {
                    -webkit-transform: translateX(20px);
                    -ms-transform: translateX(20px);
                    transform: translateX(20px);
                }
            }
        }
    
        &__slider {
            position: relative;
            cursor: pointer;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            width: 40px;
            height: 20px;
    
            &:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                top: 2px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }
    
            &__round {
                border-radius: 34px;
    
                &:before {
                    border-radius: 50%;
                }
            }
        }

        &__text {
            font-size: 14px;
            color: var(--color-text-400);
            cursor: pointer;
        }
    }
}