.layout-wrapper {
  padding: 9px;
  flex: 1;
  overflow: hidden;

  & form {
    flex: 1;
    height: 100%;
  }
}
