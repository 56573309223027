.auth-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  background-color: #13151c;
}

.login-header {
  text-align: center;
}

.auth-form-wrapper {
  margin: auto;
  /* margin-top: calc(100vw / 8.57); */
  background-color: white;
  /* height: calc(100vw / 3.75); */
  height: 512px;
  /* width: calc(100vw / 3.1); */
  width: 618px;
  border-radius: 2px;
  position: relative;
}


.auth-form-wrapper__header-background {
  width: 100%;
  /* height: calc(100vw / 16.7); */
  height: 115px;
  /* position: relative; */
  top: 0;
  left: 0;
  background-image: url('../../../public/accets/img/auth-form-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-form-wrapper__header-background_border-bottom {
  border-bottom: 1px solid #bfc6d7;
}

.auth-form-wrapper__header {
  font-family: 'Inter';
  /* font-size: calc(100vw / 73.85); */
  font-size: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  /* height: calc(100vw / 60); */
  height: 32px;
  /* line-height: calc(100vw / 60); */
  line-height: 32px;
  /* margin-top: calc(-100vw / 128); */
  margin-top: -15px;
  /* margin-bottom: calc(100vw / 96); */
  margin-bottom: 20px;
}

.auth-form__field-wrapper {
  text-align: left;
  /* width: calc(100vw / 3.28); */
  width: 586px;
  margin: auto;
  /* margin-top: calc(100vw / 130); */
  margin-top: 10px;
  position: relative;
}

.auth-form__field-name {
  /* height: calc(100vw / 120); */
  height: 16px;
  /* line-height: calc(100vw / 120); */
  line-height: 16px;
  /* font-size: calc(100vw / 160); */
  font-size: 12px;
  /* margin-left: calc(100vw / 120); */
  margin-left: 16px;

  font-family: 'Inter';
  font-weight: 600;
  color: #777b92;
}

.auth-form__star {
  vertical-align: top;
  color: #f14141;
}

.auth-form__input {
  /* width: calc(100vw / 3.4); */
  width: 100%;
  /* height: calc(100vw / 50.53); */
  height: 38px;
  background-color: #f4f6fb;
  border: 1px solid #bfc6d7;
  border-radius: 2px;
  /* margin-top: calc(100vw / 640); */
  margin-top: 3px;
  /* padding-left: calc(100vw / 120); */
  padding-left: 16px;
  padding-right: 35px;
}

.auth-form__input:focus-visible{
  outline-width: 0;
  border-color: #337eff;
  box-shadow: 0 0 0 2px #d6e5ff;
}

.auth-form__input_wrong{
  border-color: #f14141;
}

.auth-form__input_right{
  border-color: #49AB4d;
}

.auth-form__input-img {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 32px;
  right: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

.auth-form__input-img_left {
  right: 48px;
}


.auth-form__input-img_eye{
  background-image: url('../../../public/accets/img/eye.svg');
  cursor: pointer;
}

.auth-form__input-img_eye-crossed{
  background-image: url('../../../public/accets/img/eye-crossed.svg');
  cursor: pointer;
}

.auth-form__input-img_success{
  background-image: url('../../../public/accets/img/success.svg');
}

.auth-form__input-img_alert{
  background-image: url('../../../public/accets/img/alert.svg');
}

.auth-form__error {
  /* height: calc(100vw / 120); */
  height: 16px;
  /* font-size: calc(100vw / 192); */
  font-size: 10px;
  font-family: 'Inter';
  color: #f14141;
  /* margin-left: calc(100vw / 120); */
  margin-left: 16px;
  /* margin-top: calc(100vw / 480); */
  margin-top: 4px;
}

.auth-form__button-wrapper {
  /* width: calc(100vw / 3.28); */
  width: 586px;
  margin: auto;
  /* margin-top: calc(100vw / 80); */
  margin-top: 24px;
}

.auth-form__button {
  cursor: pointer;
  padding: 5px;
  border: 0;
  background-color: #337eff;
  color: white;
  width: 100%;
  /* height: calc(100vw / 48); */
  height: 40px;
  font-weight: 600;
  font-family: 'Inter';
  /* font-size: calc(100vw / 137); */
  font-size: 14px;
}

.auth-form__button_disabled{
  cursor: not-allowed;
}

.auth-form-wrapper__cancel-button {
  cursor: pointer;
  padding: 5px;
  border: 0;
  background-color: white;
  color: #777b92;
  width: 100%;
  /* height: calc(100vw / 48); */
  height: 40px;
  font-weight: 600;
  font-family: 'Inter';
  /* font-size: calc(100vw / 137); */
  font-size: 14px;
  /* margin-top: calc(100vw / 240); */
  margin-top: 8px;
}

.auth-form-wrapper__footer {
  text-align: center;
  /* margin-top: calc(100vw / 120); */
  margin-top: 16px;
}

.auth-form-wrapper__caption {
  font-weight: 400;
  /* font-size: calc(100vw / 137); */
  font-size: 14px;
  /* line-height: calc(100vw / 96); */
  line-height: 20px;
  color: #50546b;
  display: block;
}

.auth-form-wrapper__caption_underline {
  text-decoration: underline;
}

.lock__wrapper{
  display: flex;
  height: 150px;
  /* background-color: aqua; */
  margin-top: -75px;
  justify-content: center;
}

.lock__circle-1{
  height: 100%;
  width: 150px;
  border: 1px solid #d6dbe7;
  border-radius: 75px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.lock__circle-2{
  height: 132px;
  width: 132px;
  border: 1px solid #ff7c0a;
  border-radius: 66px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.lock__img{
  height: 72px;
  width: 72px;
  background-image: url('../../../public/accets/img/lock.svg');
  align-self: center;
}

.error-code-caption{
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #777b92;
  margin-top: 32px;
  text-align: center;
}

.error-caption{
  font-size: 26px;
  line-height: 32px;
  font-weight: 800;
  color: #20222b;
  text-align: center;
  margin-top: 16px;
  text-transform: uppercase;
}

.reason-caption{
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #777b92;
  text-align: center;
  margin-top: 16px;
}

.recomend-caption-1{
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #50546b;
  text-align: center;
  margin-top: 60px;
}

.recomend-caption-2{
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #50546b;
  text-align: center;
}