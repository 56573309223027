.layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    overflow: hidden;
  }
}