.info-row{
    height: 48px;
    border: 1px solid #d6dbe7;
    /* margin-top: 8px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-row_inner{
    border-top: 0;
    border-right: 1px solid #d6dbe7;
    border-bottom: 1px solid #d6dbe7;
    border-left: 1px solid #d6dbe7;
}

.info-row__item{
    height: 24px;
    padding: 0 16px;
    border-right: 1px solid #bfc6d7;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.info-row__item_no-border{
    border: 0;
}