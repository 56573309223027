.statisticRequestReportFormWrapper {
    height: calc(100vh - 207px);
    flex-direction: column;
    overflow-y: scroll;

    &__column {
        overflow-y: visible;
    }
}

/*
@import '@/theme/main.scss';

.model-complect-general {
  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    @include flex-v-center;
    flex: 0 0 48px;
    margin-bottom: 8px;
    border: 1px solid var(--color-grey-200);
  }

  &__footer {
    @include flex-v-center;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid var(--color-grey-200);
    border-top: unset;
  }
}

.main-header-block {
  @include flex-v-center;
  flex: 1;

  &__title {
    @include static_title_h4;
    padding-left: 16px;
  }

  &__item {
    @include flex-v-center;
    padding: 0 16px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__code {
    justify-content: end;
    flex-grow: 1;
    gap: 8px;
    border-right: none;
  }
}

.additional-header-block {
  @include flex-v-center;
  gap: 8px;
  padding: 0 16px;
}

.main-form-block {
  display: flex;
  overflow: hidden;
  border: 1px solid var(--color-grey-200);
  height: 100%;

  &__row {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 16px;

    &__block {
      padding: 16px 0;
    }
  }

  &__column:first-child {
    border-right: 1px solid var(--color-grey-200);
  }

  &__file-uploader-wrapper {
    flex: 0 0 auto;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  & .file-uploader-list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__file-item {
      color: var(--color-primary);
      display: flex;
      align-items: center;
      gap: 10px;

      a {
        color: var(--color-primary);
        text-decoration: underline;
      }
    }

    & .file-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("public/accets/img/file.svg");
      align-self: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    & .cross-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url("public/accets/img/cross-blue.svg");
      align-self: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__control {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__link {
    color: #8d91a4;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  &__specs {
    width: 100%;
    display: flex;
    gap: 16px;
    padding: 16px;
  }

  &__product-specs {
    flex: 1;
    overflow-y: scroll;
  }
}

.main-footer-block {
  &__buttons {
    flex: 1;
    @include flex-v-center;
    justify-content: flex-end;
    gap: 16px;
  }

  &__toggles {
    flex: 1;
    @include flex-v-center;
    gap: 16px;
  }
}

.required {
  color: var(--color-danger);
}
*/
