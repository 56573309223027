.side-element{
    display: flex;
    /* min-height: 40px; */
    height: auto;
    padding: 10px 0;
    align-items: center;
    border-bottom: 1px solid #404355;
}

.side-element_subcategory{
    padding-left: 15px;
    position: relative;
}

.side-element_current{
    background-color: #295095;
}

.side-element_current::before {
    content: '';
    background: #337eff;
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
}

.side-group-element_current{
    background-color: #295095;
}

.side-element__img{
    min-height: 13px;
    min-width: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../public/accets/img/side/dir.svg');
    margin-left: 16px;
}

.side-element__caption{
    margin-left: 9px;
    flex-grow: 1;
    cursor: pointer;
}

.side-element__caption_bold{
    font-weight: 800;
}

.side-element__collapse-button{
    height: 100%;
    width: 32px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.collapse-button__img{

}

.collapse-button__img_open{
    width: 9px;
    height: 5px;
    background-image: url('../../../public/accets/img/side/arrow_dawn.svg');
}

.collapse-button__img_close{
    width: 5px;
    height: 9px;
    background-image: url('../../../public/accets/img/side/arrow_right.svg');
}