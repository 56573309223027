@import "./theme/main.scss";

@font-face {
  font-family: "Inter";
  src: url("../public/accets/fonts/Inter/static/Inter-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/accets/fonts/Inter/static/Inter-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/accets/fonts/Inter/static/Inter-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../public/accets/fonts/Work_Sans/static/WorkSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

// TODO: move to theme
.horizontal-line {
  border-top: 1px solid var(--color-grey-200);
	margin-bottom: 16px;
}
