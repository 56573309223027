
.field__textarea {
    height: 100%;
    max-height: 230px;
    min-height: 230px;
    overflow: auto;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    padding: 6px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-300);
    letter-spacing: -0.2px;
}