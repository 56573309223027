.import-page {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;

    &__content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        overflow: hidden;
    }

    &__work-area {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 9px;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        padding: 16px 0 5px;

        .buttons__group {
            padding: 0 16px;
            width: 50%;
        }

        .button__secondary__ghost {
            margin-left: auto;
        }
    }

    // .guides-table {
    //     table-layout: fixed;
    // }
}

.guides-table {
    border-collapse: collapse;
    width: -webkit-fill-available;
    width: max-content;
    table-layout: fixed;

    &-wrapper {
        border: 1px solid #d6dbe7;
        margin-top: 8px;
        padding: 16px;
        overflow: scroll;
        min-width: 50%;
        height: 100%;
    }

    &__full {
        width: 100%;
    }

    &__row {
        cursor: pointer;
    }

    &__th {
        font-weight: 500;
        font-size: 12px;
        color: #20222b;
        text-align: left;
        padding: 0 8px;
        background-color: var(--color-grey-100);
        border: 0.5px solid var(--color-grey-200);
        height: 32px;
    }

    &__td {
        font-weight: 400;
        font-size: 14px;
        color: #20222b;
        text-align: left;
        padding: 0 8px;
        border: 0.5px solid var(--color-grey-200);
        height: 30px;

        &_active {
            background-color: var(--color-grey-200);
        }

        &_checked {
            padding: 0 8px;
            border: 0.5px solid var(--color-grey-200);
            height: 30px;
        }

        &_marked {
            background-color: #ffe5ce;
        }
    }

    &__error {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 0 0 8px
    }
}

.red-text {
    color: var(--color-danger);
}

.work-area {
    &__item {
        border: 1px solid #D6DBE7;
        border-radius: 2px;
        padding: 16px;
        overflow: auto;
        
        &--no-diff {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &-wrapper{
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            background-color: rgba($color: white, $alpha: 0.7);
        }
    }

    &__head {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        margin-bottom: 8px;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        // height: 100%;
        height: calc(100vh - 200px);
    }

    &__title {
        border: 1px solid #D6DBE7;
        padding: 14px 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #20222B;
    }
}