.button {
    @include flex-all-center;
    border-radius: 2px;
    padding: 11px 16px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    min-height: 32px;
    cursor: pointer;
    white-space: nowrap;

    &__small {
        padding: 8px 16px;
    }

    &__big {
        padding: 8px 32px;
    }

    &__primary {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-white);

        &__outline {
            background-color: inherit;
            color: var(--color-primary);
        }

        &__ghost {
            border: none;
            background-color: inherit;
            color: var(--color-primary);
        }

				&:disabled {
					background-color: var(--color-grey-100);
					color: var(--color-grey-300);
					border-color: var(--color-grey-100);
					cursor: not-allowed;
					pointer-events: all;
				}
    }

    &__negative {
        background-color: var(--color-danger);
        color: var(--color-white);
        border-color: var(--color-danger);

        &__outline {
            background-color: inherit;
            color: var(--color-danger);
        }

        &__ghost {
            border: none;
            background-color: inherit;
            color: var(--color-danger);
        }
    }

    &__secondary {
        background-color: var(--color-secondary);
        color: var(--color-white);
        border-color: var(--color-secondary);

        &__outline {
            background-color: inherit;
            color: var(--color-secondary);
        }

        &__ghost {
            border: none;
            background-color: inherit;
            color: var(--color-secondary);
        }
    }

    &__buttons-wrapper {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    &__disabled {
        background-color: var(--color-grey-100);
        color: var(--color-grey-300);
        border-color: var(--color-grey-100);
        cursor: not-allowed;
        pointer-events: all;
    }

    &_no-border{
        border: 0;
        padding: 0;
    }

    &:disabled {
        background-color: var(--color-grey-100);
        color: var(--color-grey-300);
        border-color: var(--color-grey-100);
        cursor: not-allowed;
        pointer-events: all;
    }
}

.buttons__group {
    display: flex;
    gap: 16px;
}