@import "../../theme/main.scss";

.form-toggle-switch {
  @include flex-v-center;
  gap: 8px;

  & .toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  & .toggle-switch input[type="checkbox"] {
    display: none;
  }

  & .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: var(--color-grey-300);
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  & .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: var(--color-white);
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  & .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(20px);
  }
  & .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: var(--color-primary);
  }

  &__label {
    @include static_title_h4;
    font-weight: 400;
    cursor: pointer;
  }
}