@import '../../../theme/main.scss';

.modal-history {
  &__wrapper {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;

    &_active {
      display: block;
    }
  }
  &__glass {
    position: relative;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    background-color: rgba(119, 123, 146, 0.5);
  }
}
.container {
  &-block {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    height: auto;
    max-height: 80%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 1;

    &__header {
      padding: 8px 16px;
      @include flex-all-sb;
      width: 100%;
      gap: 16px;
      border-bottom: 1px solid var(--color-grey-200);

      &-name {
        @include static_title_h4;
      }
      &-name:first-child {
        border-right: 2px solid var(--color-grey-200);
        margin-right: 8px;
        padding-right: 8px;
      }
      &-names {
        display: flex;
        flex-direction: row;
      }
    }

    &__close {
      width: 32px;
      height: 32px;
      cursor: pointer;
      @include flex-all-center;
    }

    &__content {
      padding: 16px;
      height: 100%;
      overflow: auto;
    }

  }
  &-table {
    width: 100%;
    border-collapse: collapse;
    border: 0.5px solid var(--color-grey-200);
    &__th {
      font-weight: 500;
      font-size: 12px;
      color: #20222b;
      text-align: left;
      padding: 0 8px;
      background-color: var(--color-grey-100);
      border: 0.5px solid var(--color-grey-200);
      height: 32px;

      &[colspan] {
        text-align: center;
      }
    }

    &__td {
       font-weight: 400;
       font-size: 14px;
       color: #20222b;
       text-align: left;
       padding: 0 8px;
       border: 0.5px solid var(--color-grey-200);
       height: 30px;
       &-color {
         background-color: #dbeedb;
       }
     }
    &-none {
      display: none;
    }
    &-empty {
      border: none;
      text-align: center;
      font-size: 14px;
      color: #20222b;
    }
  }
}

