.radio-button {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  &__wrapper {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  & input[type="radio"] {
    display: none;
  }

  & .custom-radio {
    position: absolute;
    cursor: pointer;
    background-color: var(--color-grey-100);
    border: 1px solid var(--color-grey-300);
    border-radius: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: border 0.2s ease;
  }

  & input[type="radio"]:checked,
  input[type="radio"]:hover {
    & + .custom-radio {
      border: 5px solid var(--color-primary);
    }
  }
}
