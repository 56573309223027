.no-data{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header{
        font-weight: 600;
        font-size: 14px;
        color: var(--color-text-400);
        margin-bottom: 16px;
    }

    &__caption{
        font-weight: 400;
        font-size: 14px;
        color: var(--color-grey-400);
    }
}