.side-tree-menu {
  height: 100%;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  background-color: #303340;
  color: #f4f6fb;
  display: flex;
  flex-direction: column;
}

.side-tree-menu-header {
  flex: 0 0 auto;
  min-height: 40px;
  border-bottom: 1px solid #404355;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__caption {
    display: flex;
    padding: 10px 8px 10px 16px;
    font-weight: 600;
    font-size: 14px;
  }

  &__img {
    height: 15px;
    width: 11px;
    background-image: url("../../../public/accets/img/side/hierarchy.svg");
    background-repeat: no-repeat;
    padding-left: 19px;
    cursor: pointer;
  }
}

.side-tree-menu-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
