html {
    box-sizing: border-box;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

.clickable {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
    }
}

.scrollbar__custom {
    scrollbar-color: var(--color-gray-300) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--color-grey-300);
    }
}