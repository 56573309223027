@import "../../theme/main.scss";

.file-uploader {
  height: 160px;
  width: 100%;
  background-color: var(--color-grey-100);
  border: 2px dashed var(--color-grey-400);
  border-radius: 2px;

  &--drag {
    background-color: var(--color-grey-300)
  }

  &--disabled {
    opacity: 0.5;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text-400);
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-grey-400);
  }
}

#file-upload {
  display: none;
}
