.bread-crumbs{
    display: flex;
    height: 48px;
    background-color: #ffffff;
    border-bottom: 1px solid #d6dbe7;
    align-items: center;
    padding-left: 34px;
}

.bread-crumbs_hidden{
    display: none;
}

.bread-crumbs__point_home{
    height: 16px;
    width: 16px;
    background-image: url("../../../public/accets/img/home.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.bread-crumbs__separator{
    height: 16px;
    width: 16px;
    margin-left: 8px;
    background-image: url("../../../public/accets/img/chevron-right.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.bread-crumbs__point{
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #a1a5b7;
    cursor: pointer;
}

.bread-crumbs__point_active{
    color: #333333;
}