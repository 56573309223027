.description {
  height: 100%;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.description_outer {
}

.description_inner {
}

.description__preview {
  font-size: 14px;
  font-weight: 400;
  color: #50546b;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  max-height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.description-modal__wrapper {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.description-modal__wrapper_visable{
  display: flex;
}

.description-modal {
  width: 788px;
  height: 386px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  flex-direction: column;
}

.description-modal__header{
    height: 50px;
    border-bottom: 1px solid #d6dbe7;
    padding: 0 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.description-modal__header-caption{
    font-size: 14px;
    font-weight: 600;
}

.description-modal__header-button{
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.description-modal__header-button-img{
    height: 16px;
    width: 16px;
    background-image: url('../../../public/accets/img/description/cross.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.description-modal__content{
    flex-grow: 1;
    padding: 0 16px;
    display: flex;
    align-items: center;
    position: relative;
}

.description-modal__text-content{
    height: 249px;
    width: 752px;
    border: 1px solid #d6dbe7;
    border-radius: 2px;
    padding: 11px 65px 11px 14px;
    overflow: scroll;
    cursor: text;
    font-size: 14px;
    font-weight: 400;
    color: #50546b;
}

.description-modal__content-button{
  height: 32px;
  width: 32px;
  position: absolute;
  top: 16px;
  right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.description-modal__content-button-img{
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../public/accets/img/page_3/doubleArrowUp.svg');
}

.description-modal__footer{
    height: 72px;
    border-top: 1px solid #d6dbe7;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 16px;
}

.description-modal__footer-button{
    height: 40px;
    width: 93px;
    background-color: #337eff;
    border-radius: 2px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.description-modal__span_selected{
  background-color: #d6dbe7;
}

.hidden {
    color: var(--color-grey-300)
}

