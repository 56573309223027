@mixin bg-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin flex-v-center { 
    display: flex;
    align-items: center;
}

@mixin flex-h-center {
    display: flex;
    justify-content: center;
}

@mixin flex-h-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-all-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-all-end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@mixin flex-all-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin basic-input {
    background: var(--color-grey-100);
    border: 1px solid var(--color-grey-300);
    border-radius: 2px;
    padding: 8px;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-400);
    height: 40px;

    &:disabled {
        background: var(--color-disable);
        border: none;
    }
}

@mixin basic-textarea {
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-300);
    max-width: 100%;
    min-width: 100%;
    width: 100%;
}

@mixin basic-input-date {
    background: var(--color-grey-100);
    border: 1px solid var(--color-grey-300);
    border-radius: 2px;
    padding: 8px;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-400);
}

@mixin basic-select {
    border: 1px solid var(--color-grey-300);
    padding: 8px;
    padding-right: 20px;
    border-radius: 2px;
    appearance: none;
    background: var(--color-grey-100) url(../../theme/svg/dropdown_select.svg) no-repeat right / 16px 16px;
    background-position-x: calc(100% - 6px);
    cursor: pointer;
    text-overflow: ellipsis;
    min-width: 125px;
    height: 40px;
}