
.checkbox__block {
  position: relative;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked+span::before {
      border-color: var(--color-primary);
      background-color: var(--color-primary);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      background-size: 10px 10px;
    }
  
    &:disabled+span::before {
      background-color: #e9ecef;
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    user-select: none;

    &::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid var(--color-grey-300);
      border-radius: 2px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      margin-right: 5px;
    }
  }

}
