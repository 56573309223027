.product-element {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;



  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
		height: auto;
    min-height: 65px;
    border: 1px solid #d6dbe7;
    padding: 0 13px;
    justify-content: space-between;

    &_hidden {
      background-color: var(--color-hidden);
    }
  }

  &__header-info {
    display: flex;
    flex-direction: row;
		align-items: center;
  }

  &__header-img {
    height: 32px;
    width: 32px;
    background-image: url('../../../public/accets/img/page_3/element_img.svg');
    margin-right: 25px;

    &_active {
      background-image: url('../../../public/accets/img/page_3/element_img_active.svg');
    }

    &_hidden {
      background-image: url('../../../public/accets/img/page_3/element_img_hidden.svg');
    }
  }

  &__header-type {
    margin-right: 25px;
    font-size: 20px;
    font-weight: 800;
    color: #303549;

    &_hidden {
      color: var(--color-grey-300);
    }
  }

  &__header-name {
    font-size: 20px;
    font-weight: 600;
    color: #606580;

    &_hidden {
      color: var(--color-grey-300);
    }
  }

  &__header-control {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__header-arrow {
    height: 16px;
    width: 16px;

    &_down {
      background-image: url('../../../public/accets/img/page_3/doubleArrowDown.svg');
    }

    &_up {
      background-image: url('../../../public/accets/img/page_3/doubleArrowUp.svg');
    }
  }

  &__description {
    display: flex;
    height: 102px;
    border-left: 1px solid #d6dbe7;
    border-right: 1px solid #d6dbe7;
    border-bottom: 1px solid #d6dbe7;
  }
}