.category-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category-card__header {
  line-height: 24px;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: #303549;
}

.category-card__header::first-letter {
  text-transform: capitalize;
}

.category-card__image-wrapper {
  border: 1px solid #d6dbe7;
  background-color: #f9f9f9;
  margin-right: 24px;
	min-width: 300px;
  height: 100%;
  box-shadow: -8px 0 32px 0 rgba(191, 197, 215, 0.5);
  background-repeat: no-repeat;
}

.category-card__image {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100% 100%;
}

.category-card__image_car {
  background-image: url('../../../public/accets/img/search_img/cars_l.png');
}

.category-card__image_motorcicle {
  background-image: url("../../../public/accets/img/search_img/moto.png");
}

.category-card__image_track{
  background-image: url("../../../public/accets/img/search_img/truck.png");
}

.category-card__image_spectech{
  background-image: url("../../../public/accets/img/search_img/forklift.png");
}

.category-card__image_sudovoe{
  background-image: url("../../../public/accets/img/search_img/ship.png");
}

.category-card__image_prom{
  background-image: url("../../../public/accets/img/search_img/prom.png");
}