@import '../../theme/main.scss';

.mark-guide {
  &__wrapper {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    background-color: rgba(119, 123, 146, 0.5);

    &_active {
      display: block;
    }
  }
}



.guide {
  &-block {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    width: 780px;
    height: auto;
    max-height: 80%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    right: 0;

    &__header {
      padding: 8px 16px;
      @include flex-all-sb;
      width: 100%;
      gap: 16px;
      border-bottom: 1px solid var(--color-grey-200);

      &__name {
        @include static_title_h4;
      }
    }

    &__close {
      width: 32px;
      height: 32px;
      cursor: pointer;
      @include flex-all-center;
    }

    &__content {
      padding: 16px;
      height: 100%;
      overflow: auto;

      &__item {
        margin-bottom: 16px;
        display: flex;
        gap: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__divider {
        width: 100%;
        display: block;
        border-bottom: 1px solid var(--color-grey-200);
        padding-top: 20px;
        margin-bottom: 16px;
      }

      &__grid {
        display: grid;
        grid-template-columns: 125px 1fr 185px;
        gap: 16px;
        align-items: flex-end;
        margin-bottom: 16px;
      }

    }

    &__control {
      @include flex-v-center;

      &_elastic {
        margin-left: auto;
      }

      &-column {
        @include flex-h-column;
        gap: 4px;
        width: 100%;

        & div:first-child {
            padding-left: 8px;
        }
      }

      &-title {
        @include input_label;
        margin-right: 3px;

        &__required {
            &::after {
                content: '*';
                font-size: 12px;
                color: var(--color-danger);
                margin-left: 4px;
            }
        }
      }
    }

    &__input {
      @include basic-input;

      &__small {
        max-width: 105px;
        height: 32px;
      }

      &_error{
        color: var(--color-danger);
      }
    }

    &__error{
      display: none;
      
      &_active{
        display: flex;
      }
    }

    &__textarea {
      @include basic-textarea;
      min-height: 65px;
      max-height: 65px;
    }

    &__select {
      @include basic-select;
      width: 100%;
    }

    &__bottom {
      padding: 16px;
      @include flex-all-sb;
      width: 100%;
      gap: 8px;
      border-top: 1px solid var(--color-grey-200);
      flex-grow: 1;
    }
  }
}
