@import "../../theme/main.scss";

.date-picker {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    @include input_label;
    padding-left: 16px;
  }

  &__field {
    @include basic-input;
    width: 100%;
    transition: border-color 0.2s ease;
    display: flex;
    align-items: center;

    &.disabled {
      pointer-events: none;
      background-color: var(--color-disable);
    }
    
    & span {
      flex: 1;
    }

    &:hover {
      border-color: var(--color-primary);
    }

    &:focus {
      border-color: var(--color-primary);
    }

    &:focus-visible {
      outline: 1px solid hsl(218, 100%, 70%);
    }

    &--error {
      border-color: var(--color-danger);
    }
  }

  &__placeholder {
    color: var(--color-grey-300);
  }

  &__error {
    height: 16px;
    padding-left: 16px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--color-danger);
  }

  &__textarea {
    resize: vertical;
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 40px;

    &:hover {
      & svg {
        fill: var(--color-primary);
      }
    }
  }

  &__calendar {
    position: absolute;
    z-index: 10;
    top: 60px;
    margin-top: 4px;
  }
}
