.multiselect-block {
  flex: 1;
  padding: 19px 16px 0;
  border: 1px solid var(--color-grey-200);

  &__options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
    overflow-y: scroll;
    height: calc(100% - 56px);
  }
}
