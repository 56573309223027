@import "../../theme/main.scss";

.input-block {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__field {
    @include basic-input;
    padding-right: 80px;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--color-primary);
    }

    &:focus {
      border-color: var(--color-primary);
    }

    &:focus-visible {
      outline: 1px solid hsl(218, 100%, 70%);
    }

    &--error {
      border-color: var(--color-danger);
    }
  }

  &__error {
    height: 16px;
    padding-left: 16px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--color-danger);
  }

  &--flex {
    flex-direction: row;
  }

  & .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  &__icons {
    position: absolute;
    top: 0;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 40px;
    padding: 0;
  }
}
