@import "src/theme/main";

.selector-list {
  width: 100%;
  max-width: 1248px;
  margin: auto;

  &__row {
    width: 100%;
  }

  &__fields {
    width: 100%;
    @include flex-all-sb;
    flex-direction: column;
  }

  &__contant {
    @include flex-h-column;
    width: 100%;
    max-width: 1248px;
  }

  &__under-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-text-200);
    margin-top: 40px;
    text-align: center;
  }
}

.selector-list.waiting {
  pointer-events: none;
  opacity: 0.5;
}

.selector-list_search {
  height: auto;
}

.selector-list_padding-top {
  padding-top: 60px;
}

.selector-list__under-header_hide {
  display: none;
}

.selector-list__header {
  text-align: center;
  /* margin-top: calc(100vw/30.97); */
  margin-top: 16px;
  /* font-family: 'Work Sans'; */
  font-weight: 700;
  /* font-size: calc(100vw/55.5); */
  font-size: 34px;
  display: flex;
  justify-content: center;
  color: #272a38;
}

.selector-list__header_more-margin-top {
  margin-top: 60px;
}

.selector-list__search-header {
  text-align: center;
  /* margin-top: calc(100vw/30.97); */
  /* margin-top: 16px; */
  /* font-family: 'Work Sans'; */
  font-weight: 700;
  /* font-size: calc(100vw/55.5); */
  font-size: 20px;
  line-height: 24px;

  display: flex;
  justify-content: center;
  color: #606580;
}

.selector-list__table {
  display: flex;
  margin-top: 54px;
}

.selector-list__search-table {
  display: flex;
  margin-top: 60px;
}

.selector-list__search-fields {
  min-height: 136px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: flex-end;
}

.selector-list__row {
  /* background-color: bisque; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  /* display: table-row; */

  .dropdown-block {
    width: 300px;
  }
}

.selector-list__button {
  width: 300px;
  /* height: calc(100vw/48); */
  height: 39px;
  font-family: "Inter";
  font-weight: 600;
  /* font-size: calc(100vw/137.14); */
  font-size: 14px;
  /* line-height: calc(100vw/120); */
  line-height: 16px;
  color: white;
  background-color: #a1a5b7;
  border: 0;
  border-radius: 2px;
  /* margin-left: 16px; */
}

.selector-list__button_hide {
  visibility: hidden;
}

.selector-list__button_active {
  background-color: #337eff;
  cursor: pointer;
}

.keywords-search {
  &__container {
    margin-top: 30px;
    @include flex-all-center;
    flex-direction: column;
    width: 100%;
  }

  &__subheader {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-text-200);
    margin-top: 20px;
    text-align: center;
  }

  &__form {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1248px;
    margin-top: 50px;

    .button__primary {
      min-width: 300px;
      margin-left: 16px;
    }
  }

  &__radio-buttons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 1248px;
  }

  .radio-button__wrapper {
    margin-right: 24px;
  }

  .productSearchTree {
    max-width: 1248px;
    margin-top: 8px;
    width: 100%;
    padding-bottom: 32px;
    margin: 0 auto;
  }
}

.saveSelectedModelsButton {
  margin-bottom: 16px;
}

.search-result {
  padding-top: 60px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;

  &-table-wrapper {
    width: 100%;
    max-width: 1248px;
    border: 1px solid #d6d8e7;
    margin: auto;
  }

  .search-result-table {
    border-collapse: collapse;
    margin: auto;
    width: 100%;

    &__td {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      height: auto;
      border-bottom: 1px solid #d6d8e7;
      padding: 10px;
      word-break: break-word;
    }

    &__th {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      padding: 10px;
      background-color: #d6e5ff;
      border-top: 1px solid #d6d8e7;
      border-bottom: 1px solid #d6d8e7;
    }
  }
}

.search-result-table__no-data {
  display: flex;
  font-size: 30px;
  font-weight: 500;
  justify-content: center;
  margin-top: 70px;
}

.search-result-table__th_left {
  border-left: 1px solid #d6d8e7;
}

.search-result-table__th_right {
  border-right: 1px solid #d6d8e7;
}

.search-result-table__td_dark {
  background-color: #f4f6fb;
}

.search-result-table__td_left {
  border-left: 1px solid #d6d8e7;
}

.search-result-table__td_right {
  border-right: 1px solid #d6d8e7;
  padding-right: 20px;
}

.search-result-table__row-button {
  height: 32px;
  width: 32px;
  background-color: #337eff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result-table__row-button-image {
  height: 9px;
  width: 5px;
  background-image: url("../../../public/accets/img/arrow-right.svg");
  background-repeat: no-repeat;
}

.search-result-table-controls__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-wrapper {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.search-result-table-controls {
  display: flex;
  flex-direction: row;
  width: calc(100vw / 1.17);
  min-width: 1230px;
  padding: 0 20px;
  /* margin: auto; */
  /* margin-top: 38px; */
}

.controls__central-area {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
}

.more-results {
  height: 40px;
  width: 216px;
  border-radius: 2px;
  border: 1px solid #337eff;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #337eff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroll-to-top-button {
  display: flex;
  height: 62px;
  width: 62px;
  border-radius: 31px;
  background-color: #f4f6fb;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroll-to-top-button_hide {
  visibility: hidden;
}

.scroll-to-top-button__img {
  height: 16px;
  width: 16px;
  background-image: url("../../../public/accets/img/arrow-up.svg");
}

.selected-result {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;
    max-width: 1248px;
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-grow: 1;
  }
}

.product-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 16px;
  height: auto;
  min-height: 64px;
  margin-top: 16px;
  margin-bottom: 8px;
  border: 1px solid #d6dbe7;
  align-items: center;

  &__caption {
    color: #0089ff;
    font-weight: 600;
    font-size: 40px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.hidden {
    background-color: var(--color-hidden);
  }

  &.hidden &__caption {
    color: var(--color-grey-300);
  }
}

.product-elements-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.ant-tree.ant-tree-directory
  .ant-tree-treenode-selected
  .ant-tree-node-content-wrapper::before {
  background: rgba(0, 0, 0, 0.04) !important;
}

.ant-tree.ant-tree-directory
  .ant-tree-treenode-selected
  .ant-tree-node-content-wrapper {
  color: inherit !important;
  background: white !important;
}
