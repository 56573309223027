@import "../../theme/main.scss";

.form-input {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    @include input_label;
    padding-left: 16px; 
  }

	&__element {
		position: relative;
	}

  &__field {
    @include basic-input;
    width: 100%;
    padding-right: 40px;
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--color-primary);
    }

    &:focus {
      border-color: var(--color-primary);
    }

    &:focus-visible {
      outline: 1px solid hsl(218, 100%, 70%);
    }

    &--error {
      padding-right: 80px;
      border-color: var(--color-danger);
    }
  }

  &__error {
    height: 16px;
    padding-left: 16px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--color-danger);
  }

  &__textarea {
    resize: vertical;
  }

  &__icons {
    position: absolute;
    top: 0px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 40px;
    padding: 0 !important;
  }

  &.code-input {
    flex: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    & .form-input__icons {
      top: 0
    }

    & .form-input__icon {
      top: 0;
      height: 32px;
    }

    & input {
      height: 34px;
      width: 150px;
    }
  } 
}