.report-row{
    border: 1px solid #d6dbe7;
    border-bottom: 0;
    display: flex;
    flex-direction: row;
}

.report-row_last{
    border: 1px solid #d6dbe7;
}

.report-cell{
    width: 295px;
    padding-left: 8px;
    border-right: 1px solid #d6dbe7;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #20222b;
    align-items: center;
}

.report-cell.hidden {
      color: var(--color-text-300);
      background-color: var(--color-hidden);
}

.report-cell_last{
    width: 885px;
    border: 0;
    padding-left: 0;
    flex-grow: 1;
}

.report-cell_interactive{
    text-decoration: underline;
    cursor: pointer;
}