.guides-page{
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.guides-page__content{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
}

.guides-page__workplace{
    display: flex;
    flex-direction: column;
    width: calc(100% - 360px);
    flex-grow: 1;
    padding: 9px;
}