.product-list__item_inRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--color-grey-200);
  border-radius: 8px;
  padding: 2px 4px ;
}

.product-list__item_inRow.hidden {
    background-color: var(--color-hidden);
}

.product-list__item.hidden .pl-item__caption_inRow {
    color: var(--color-text-300);
}

.product-list__item_inRow:hover {
  cursor: pointer;
}
