.textAreaDesc {
    position: relative;
    text-align: start;
}
.textAreaDesc__icon {
    position: absolute;
    right: 14px;
    top: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
}
.textareaDesc__icon-rotate {
    transform: rotate(0deg);
}
.textareaDesc__field {
    height: 64px;
    max-height: 220px;
    min-height: 64px;
    resize: none;
    overflow: hidden;
    display: -webkit-box;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    padding: 6px 35px 6px 6px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    color: var(--color-text-300);
    letter-spacing: -0.2px;
    line-height: 200%;
}
.textareaDesc__field-name {
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    margin-left: 16px;
    font-family: 'Inter';
    font-weight: 600;
    color: #777b92;
    text-align: left;
}
.textareaDesc__field-property {
    height: 220px;
    overflow-y: auto;
}
.textareaDesc__field-clamp {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
