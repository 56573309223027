@import '../../theme/main.scss';

.model-guide {
  &__wrapper {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    background-color: rgba(119, 123, 146, 0.5);
		z-index: 10;

    &_active {
      display: block;
    }
    & .alert-icon {
      position: absolute;
      right: 4px;
      width: 16px;
      height: 16px;
      background-image: url('../../../public/accets/img/alert.svg');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
    & .alert-border {
      border: 1px solid var(--color-danger);
    }

    & .button__disabled {
      background-color: #d6dbe7;
      color: #fff;
      cursor: default;
    }
    & .cursor__pointer {
      cursor: pointer;
    }
    & .tooltip {
      height: 40px;
      position: absolute;
      z-index: 1000;
      width: 510px;
      left: -290px;
      background: black;
      color: #fff;
      padding: 8px;
      top: 32px;
      line-height: 24px;
      &-arrow {
        width: 12px;
        height: 10px;
        top: -35px;
        position: relative;
        left: 285px;
        background: black;
        transform: rotate(45deg);
      }
    }
    & .modalTC {
      &__wrapper {
        display: none;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        right: 0;
        background-color: rgba(119, 123, 146, 0.5);

        &-active {
          display: block;
        }
      }
      &__container {
        width: 400px;
        height: 296px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.-active {
        opacity: 1;
        pointer-events: all;
        background-color: rgba(119, 123, 146, 0.5);
      }
      & .modal-container {
        width: 432px;
        background: var(--color-white);
        border: 1px solid var(--color-disable);
        box-shadow: 0px 8px 32px hsla(222, 23%, 80%, 0.5);
        border-radius: 2px;
        text-align: center;
      }
      &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 32px 16px;

        & h4 {
          @include static_title_h4;
          font-weight: 800;
          text-align: center;
        }

        & div {
          @include body-text-14;
        }
      }
      &__buttons {
        padding: 16px;
      }
      & .modal-button {
        width: 100%;
        margin-bottom: 8px;
      }
    }
    & .input-search__wrap {
      position: relative;
    }
    & .input-search__list {
      display: none;
      position: absolute;
      width: 100%;
      top: 58px;
      background: #f3f3f3;
      overflow: auto;
    }
    & .height {
      display: block;
      z-index: 1;
      height: 200px;
    }
    & .input-search__item {
      height: 32px;
      line-height: 32px;
      padding: 4px;
      text-align: start;
    }
    & .input-search__item:hover {
      background-color: var(--color-primary);
      opacity: .8;
      color: #fff;
      cursor: pointer;
    }
  }

  &-block {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-200);
    border-radius: 2px;
    width: 780px;
    height: auto;
    max-height: 80%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    right: 0;

    &__header {
      padding: 8px 16px;
      @include flex-all-sb;
      width: 100%;
      gap: 16px;
      border-bottom: 1px solid var(--color-grey-200);

      &__name {
        @include static_title_h4;
      }
    }

    &__close {
      width: 32px;
      height: 32px;
      cursor: pointer;
      @include flex-all-center;
    }

    &__content {
      padding: 16px;
      height: 100%;
      overflow: auto;

      &__item {
        margin-bottom: 16px;
        display: flex;
        gap: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__divider {
        width: 100%;
        display: block;
        border-bottom: 1px solid var(--color-grey-200);
        padding-top: 20px;
        margin-bottom: 16px;
      }

      &__grid {
        display: grid;
        grid-template-columns: 125px 1fr 185px;
        gap: 16px;
        align-items: flex-end;
        margin-bottom: 16px;
      }

    }

    &__control {
      @include flex-v-center;
      position: relative;

      &_elastic {
        margin-left: auto;
      }

      &-column {
        @include flex-h-column;
        gap: 4px;
        width: 100%;
      }

      &-title {
        @include input_label;
        margin-right: 3px;

        &__required {
          &::after {
            content: '*';
            font-size: 12px;
            color: var(--color-danger);
            margin-left: 4px;
          }
        }
      }
    }

    &__input {
      @include basic-input;
      padding-right: 16px;
      width: 100%;

      &__small {
        max-width: 105px;
        height: 32px;
      }

      &_error{
        color: var(--color-danger);
      }
    }

    &__hidden{
      display: none;
    }

    &__active{
      display: block;
    }

    &__textarea {
      @include basic-textarea;
      min-height: 65px;
      max-height: 65px;
    }

    &__select {
      @include basic-select;
      width: 100%;
    }

    &__bottom {
      padding: 16px;
      @include flex-all-sb;
      width: 100%;
      gap: 8px;
      border-top: 1px solid var(--color-grey-200);
      flex-grow: 1;
    }
  }

  &__content {
    padding: 16px;
    height: 100%;
    overflow: auto;

    &__item {
      margin-bottom: 16px;
      display: flex;
      gap: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__divider {
      width: 100%;
      display: block;
      border-bottom: 1px solid var(--color-grey-200);
      padding-top: 20px;
      margin-bottom: 16px;
    }

    &__grid {
      display: grid;
      grid-template-columns: 125px 1fr 185px;
      gap: 16px;
      align-items: flex-end;
      margin-bottom: 16px;
    }

  }
}
