@import "../../../theme/main.scss";

.side-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #404355;

  &--active {
    background-color: var(--color-primary-400);
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-wrapper {
    width: 100%;
    text-align: left;
  }

  &__img {
    min-height: 13px;
    min-width: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../../public/accets/img/side/dir.svg");
    margin-left: 16px;

    &_open {
      width: 9px;
      height: 5px;
      background-image: url("../../../../public/accets/img/side/arrow_dawn.svg");
    }

    &_close {
      width: 5px;
      height: 9px;
      background-image: url("../../../../public/accets/img/side/arrow_right.svg");
    }
  }

  &__caption {
    margin-left: 9px;
    flex-grow: 1;
    color: var(--color-white);
  }

  &__collapse-button {
    height: 100%;
    width: 32px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
