.menu {
  position: relative;

  &__items {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    background: var(--color-white);
    border: 1px solid var(--color-grey-200);
    box-shadow: 0px 8px 32px rgba(191, 198, 215, 0.5);
    border-radius: 2px;

    & a {
      color: var(--color-text-400);
    }
  }
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  min-width: max-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 16px;

  &:hover, &:focus {
    background: var(--color-light-blue-30);

    & svg {
      fill: var(--color-primary);
    }
  }
}
