@import "../../../theme/main.scss";

.chips-item {
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: var(--color-grey-200);
  padding: 1px 4px;
  gap: 4px;

  &__value {
    @include body-text-14;
    line-height: 18px;
  }

  &__icon {
    vertical-align: middle;
  }
}
