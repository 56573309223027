.field-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.field-wrapper_small {
    /* width: calc(100vw/6.4); */
    width: 300px;
}

.field-wrapper_medium {
}

.field-wrapper_large {
    width: 932px;
}

.field-wrapper_hide{
    visibility: hidden;
}